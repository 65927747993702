<!-- Primary button -->
<button (click)="onPress()"
        [disabled]="isDisabled"
        [ngClass]="{'button-primary' : variant == 'primary', 'button-secondary' : variant == 'secondary'}"
        class="border-1 border-radius-8 gap-4 py-6 px-8">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</button>

<!-- Define the template for children -->
<ng-template #content>
  <ng-content select="[children]"></ng-content>
</ng-template>
