import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

@Directive({
  standalone: true,
  selector: '[spThousandSeparator]',
  providers: [DecimalPipe],
})
export class ThousandSeparatorDirective {
  private updatingProgrammatically = false;

  constructor(
    private el: ElementRef,
    private model: NgModel,
    private control: NgControl,
    private decimalPipe: DecimalPipe
  ) {
    model.valueChanges.subscribe((value) => {
      if (!this.updatingProgrammatically) {
        const isFocused = document.activeElement === this.el.nativeElement;
        if (!isFocused) {
          this.formatValue(value);
        }
      }
    });
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const rawValue = input.value;

    // Remove non-numeric characters (except for commas)
    const numericValue = rawValue.replace(/[^0-9]/g, '');

    // Update the input field with the cleaned value
    this.el.nativeElement.value = numericValue;

    // Update the model with the unformatted numeric value
    this.control.control?.setValue(numericValue, { emitEvent: false });
    this.model.viewToModelUpdate(numericValue);
  }

  @HostListener('focus')
  onFocus() {
    const value = this.control.control?.value;
    this.el.nativeElement.value = value?.toString().replace(/,/g, '');
  }

  @HostListener('blur')
  onBlur() {
    const value = this.control.control?.value;
    this.formatValue(value);
  }

  private formatValue(value: string | number | null) {
    if (value === null || value === undefined) {
      return;
    }

    const unformattedValue = value.toString().replace(/,/g, '');
    const formattedValue = this.decimalPipe.transform(unformattedValue, '1.0-0');

    // Prevent triggering valueChanges during programmatic update
    this.updatingProgrammatically = true;

    // Update the input's value visually
    this.el.nativeElement.value = formattedValue;

    // Update the control and model values
    this.control.control?.setValue(formattedValue, { emitEvent: false });
    this.model.viewToModelUpdate(unformattedValue);

    this.updatingProgrammatically = false;
  }
}
