<div  *ngIf="!dialogData.freemium" class="card">
  <div class="card-header">
    <span>Confirm your project</span>
  </div>
  <div class="card-body">
    <div class="row">
     <div class="col-5">
       <div class="sp-card">
         <div class="card">
           <div class="card-header">
             <span class="sp-target-audience">{{ project.audience_target.name }}</span>
           </div>
           <div class="card-body">
             <span>Universes : <span class="font-light">{{ selectedUniversesToString() }}</span></span><br>
             <span>Age:
               <span *ngIf="audienceTargetSpec.ageMax <65" class="font-light">{{ audienceTargetSpec.ageMin }}-{{ audienceTargetSpec.ageMax }}</span>
               <span *ngIf="audienceTargetSpec.ageMax >=65" class="font-light">{{ audienceTargetSpec.ageMin }}-{{ audienceTargetSpec.ageMax }}+</span>
             </span><br>
             <span>Gender: <span class="font-light">{{ audienceTargetSpec.gendersString() }}</span></span><br>
             <span *ngIf="audienceTargetSpec.geolocations">Geolocation: <span class="font-light">{{ audienceTargetSpec.geolocString() }}</span></span><br>
             <div *ngFor="let flex of audienceTargetSpec.flex; let i = index"><span>{{ i == 0 ? 'Interests' : 'And' }}: <span [innerHTML]="boldOr(audienceTargetSpec.flexOrString(flex))" class="font-light"></span></span></div>
             <span *ngIf="audienceTargetSpec.exclusions">Exclude: <span [innerHTML]="boldOr(audienceTargetSpec.flexOrString(audienceTargetSpec.exclusions))" class="font-light"></span></span><br>
           </div>
           <div class="card-footer">
             <div class="row no-gutters pt-4">
               <div class="col-12">
                 <span class="sp-target-people"><span class="sp-target-number">{{ toNumberFormat(project.audience_target.fb_size) }}</span> people</span>
               </div>
             </div>
             <app-sp-audience-score [audienceTarget]="project.audience_target" [useNewIndicator]="dialogData.useNewIndicator"></app-sp-audience-score>
          </div>
         </div>
       </div>
     </div>
      <div class="col-2 d-flex align-self-center justify-content-center">
       <div class="wrapper">
         <div class="line"></div>
            <div class="wordwrapper">
              <span class="word sp-project-vs">VS</span>
            </div>
         </div>
      </div>
      <div class="col-5">
        <div class="sp-card">
          <div class="card">
            <div class="card-header">
              <span class="sp-benchmark-audience">{{ project.audience_bench.name }}</span>
            </div>
            <div class="card-body">
              <span>Universes : <span class="font-light">{{ selectedUniversesToString() }}</span></span><br>
              <span>Age:
                <span *ngIf="audienceBenchmarkSpec.ageMax <65" class="font-light">{{ audienceBenchmarkSpec.ageMin }}-{{ audienceBenchmarkSpec.ageMax }}</span>
                <span *ngIf="audienceBenchmarkSpec.ageMax >=65" class="font-light">{{ audienceBenchmarkSpec.ageMin }}-{{ audienceBenchmarkSpec.ageMax }}+</span>
              </span><br>
              <span>Gender: <span class="font-light">{{ audienceBenchmarkSpec.gendersString() }}</span></span><br>
              <span *ngIf="audienceBenchmarkSpec.geolocations">Geolocation: <span class="font-light">{{ audienceBenchmarkSpec.geolocString() }}</span></span><br>
              <div *ngFor="let flex of audienceBenchmarkSpec.flex; let i = index"><span>{{ i == 0 ? 'Interests' : 'And' }}: <span [innerHTML]="boldOr(audienceBenchmarkSpec.flexOrString(flex))" class="font-light"></span></span></div>
              <span *ngIf="audienceBenchmarkSpec.exclusions">Exclude: <span [innerHTML]="boldOr(audienceBenchmarkSpec.flexOrString(audienceBenchmarkSpec.exclusions))" class="font-light"></span></span><br>
            </div>
            <div class="card-footer">
              <div class="row no-gutters pt-4">
                <div class="col-10 d-flex justify-content-start">
                  <span class="sp-benchmark-people"><span class="sp-target-number">{{ toNumberFormat(project.audience_bench.fb_size) }}</span> people</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row no-gutters d-flex pb-4">
    <div class="col-12 text-center">
      <h3>Credits for this analysis: 1</h3><br>
      <h6 class="sp-text-secondary">Number of credits left: {{ companySub ? companySub.credits : 'Loading...' }}</h6>
      <h6 class="sp-text-secondary" *ngIf="companySub && companySub.credits_extra > 0">Number of extra credits left: {{ companySub ? companySub.credits_extra : 'Loading...' }}</h6><br>
      <button (click)="onNoClick()" [disabled]="actionPending" class="btn btn-secondary sp-btn sp-btn-secondary" matTooltip="Return to the project creation" matTooltipHideDelay="10" routerLinkActive="active" type="button">
        <span>Cancel</span>
      </button>
      <button (click)="createProject()" [disabled]="actionPending" class="ml-3 btn btn-primary sp-btn sp-btn-primary" matTooltip="Create your project" matTooltipHideDelay="10">
        <span>Confirm</span>
      </button>
    </div>
  </div>
</div>

<div *ngIf="dialogData.freemium" class="">
  <div class="card-header">
    <span>Name your project</span>
  </div>
  <div class="d-flex flex-column pb-3" style="gap:24px">
    <div class="d-flex flex-column align-items-center w-100" style="gap:8px">
      <div class="row w-100">
        <mat-form-field appearance="outline" class="w-100 m-3 sp-form-field">
          <mat-label class="sp-input-label">
            <span>Project Name</span>
          </mat-label>
          <input #projectNameInput [formControl]="projectNameControl" (change)="onProjectNameChange(projectNameInput.value)" class="sp-input-content"  matInput maxlength="25" placeholder="Type project’s name..."/>
          <span class="sp-information-tooltip sp-transform-icon" matSuffix matTooltip="To create you project, please define the name of it." matTooltipHideDelay="10" style="transform: translateY(-3px)">
                      <span class="">i</span>
                   </span>
        </mat-form-field>
      </div>
      <div>
        {{ companySub && companySub.credits > 0 ? 'Credits' : 'Extra credits' }} for this analysis: 1
      </div>
      <div>
        Number of credits left: {{ companySub ? companySub.credits > 0 ? companySub.credits - 1 : companySub.credits : 0}}
      </div>
      <div *ngIf="companySub && companySub.credits_extra > 0">
        Number of extra credits left: {{ companySub.credits <= 0 ? companySub.credits_extra - 1 : companySub.credits_extra }}
      </div>
    </div>
    <div class="d-flex justify-content-center" style="gap: 16px">
      <button (click)="onNoClick()" class="btn btn-secondary sp-btn sp-btn-secondary" matTooltip="Back to projects" matTooltipHideDelay="10"  routerLinkActive="active" type="button">
        <span>Cancel</span>
      </button>
      <button (click)="createProject()" class="btn btn-primary sp-btn sp-btn-primary" matTooltip="Update your account" matTooltipHideDelay="10" type="button">
        <span>Confirm your analysis</span>
      </button>
    </div>
  </div>
</div>

