import {StatItem} from '../../models/stats/stat-item';
import {BaseStatFilter} from '../filter/base-stat-filter';

export enum DataType {
  NORMALIZED, RAW
}

export class StatHandler {
  private static readonly TYPE_WHITELIST = [
    "ages",
    "locales",
    "education_statuses",
    "family_statuses",
    "genders",
    "life_events",
    "publisher_platforms",
    "relationship_statuses"
  ];

  private itemsData: Map<string, StatItem[]> = new Map();
  private activeFilter: Map<string, boolean> = new Map();

  get(flag: string, withFilter: boolean = true, filterName ?: string, ids?: number[]): StatItem[] | null {
    flag = (this.activeFilter.get(flag) && withFilter) ? flag + "-filtered" : flag;

    // Choose between all or filtered data
    let items = this.itemsData.get(flag);
    if (items && filterName && filterName !== "") items = items.filter(i => i.name.toLowerCase().includes(filterName.toLowerCase()))

    if (!items) return null;
    if (ids) {
      items = items.filter(i => {
        return ids.includes(i.id)
      })
    }

    return items;
  }

  has(flag: string): boolean {
    return this.itemsData.has(flag);
  }

  keys(): string[] {
    return Array.from(this.itemsData.keys());
  }

  add(flag: string, items: StatItem[]) {
    this.itemsData.set(flag, items);
    this.activeFilter.set(flag, false);

    this.applyDefaultFilter(flag);
  }

  applyDefaultFilter(flag?: string) {
    // Init filter for elements
    if (!flag || flag == "criterion") {
      this.flagFilterBasic(flag, 5, 100, 0, this.maxSelectivity(flag) * 100, []);
    }
    if (!flag || flag == "tags") {
      this.flagFilterBasic(flag, 3, 100, 0, this.maxSelectivity(flag) * 100, []);
    }
  }

  /**
   * Apply a filter for both criterion and tags
   * @param filter
   * @param flag
   */
  //TODO: Update data if filter has param, regardless of flag?
  applyBaseFilter(filter?: BaseStatFilter, flag?: string) {
    if (filter) {
      if (this.itemsData.has("criterion") && (!flag || flag == "criterion")) {
        this.flagFilterBasic("criterion", filter.criteriaPenetrationMin, filter.criteriaPenetrationMax,
          filter.criteriaSelectivityMin, filter.criteriaSelectivityMax, filter.criteriaBlacklist.map(c => c.id));
      }
      if (this.itemsData.has("tags") && (!flag || flag == "tags")) {
        this.flagFilterBasic("tags", filter.tagPenetrationMin, filter.tagPenetrationMax,
          filter.tagScorableMin, filter.tagScorableMax, filter.tagBlacklist.map(t => t.id));
      }
    } else {
      if (!flag || flag == 'criterion') {
        this.activeFilter.delete('criterion');
        this.itemsData.delete('criterion-filtered');
      }
      if (!flag || flag == 'tags') {
        this.activeFilter.delete('tags');
        this.itemsData.delete('tags-filtered');
      }
    }
  }

  /**
   * Apply a filter to a flag, data is store apart as "-filtered" variant
   * @param flag
   * @param penMin
   * @param penMax
   * @param scorableMin
   * @param scorableMax
   * @param filterId
   * @private
   */
  private flagFilterBasic(flag: string, penMin: number, penMax: number, scorableMin: number, scorableMax: number, filterId: number[]) {
    if (this.itemsData.has(flag)) {
      this.activeFilter.set(flag, true);
      this.itemsData.set(flag + "-filtered", this.itemsData.get(flag)
        .filter(s => {
          if (StatHandler.TYPE_WHITELIST.includes(s.type)) return true;

          const percent = s.percent1 * 100;
          const scorable = s.selectivity * 100;

          const percentMatch = percent >= penMin && percent <= penMax;
          const selectivityMatch = scorable >= scorableMin && scorable <= scorableMax;

          return percentMatch && selectivityMatch && !filterId.includes(s.id);
        })
      );
    }
  }

  maxSelectivity(flag: string): number {
    if (this.get(flag, false).length > 0) {
      const sorted = this.get(flag, false).sort((a, b) => b.selectivity - a.selectivity);
      return sorted[0].selectivity;
    } else {
      return 2;
    }
  }

  static sortBySelectivity(a: StatItem, b: StatItem) {
    if (a && b) {
      const res = b.selectivity - a.selectivity;
      return res != 0 ? res : b.score - a.score;
    }
    else if (a) return -a.selectivity;
    else if (b) return b.selectivity;
  }

  static sortByScore(a: StatItem, b: StatItem) {
    if (a && b) {
      const res = b.score - a.score;
      return res != 0 ? res : b.selectivity - a.selectivity;
    }
    else if (a) return -a.score;
    else if (b) return b.score;
  }

  top(key: string, size: number, sort?: (a: StatItem, b: StatItem) => number) {
    const data = sort ? this.itemsData.get(key).sort(sort).slice(0, size) : this.itemsData.get(key).slice(0, size);
    this.itemsData.set(key, data);
    return this;
  }

  copy() {
    const handler = new StatHandler();
    this.keys().forEach(k => {
      handler.add(k, this.itemsData.get(k));
    });

    return handler;
  }

  toJson() {
    const jsonHandler: any = {};
    this.itemsData.forEach((value, key) => {
      jsonHandler[key] = value;
    });

    return jsonHandler;
  }

  static fromJson(js: any) {
    const handler = new StatHandler();
    Object.entries(js).forEach(([key, value]) => {
      handler.add(key, <any>value);
    });

    return handler;
  }

  // !!! ONLY TO BE USED BY THE PROJECT MANAGER SERVICE !!! The subscription needs to be updated
  fillCustomType(type: string, typesToFill: string[]) {
    if (this.itemsData.has(type)) return;

    const items = typesToFill.flatMap(t => {
      if (this.itemsData.has(t)) {
        return this.itemsData.get(t)
      }
    });

    this.add(type, items);
  }
}
