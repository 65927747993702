import {Component, Input} from '@angular/core';
import {NgClass, NgIf, NgTemplateOutlet} from '@angular/common';

@Component({
  selector: 'app-titan-button',
  standalone: true,
  templateUrl: './titan-button.component.html',
  imports: [
    NgClass,
    NgIf,
    NgTemplateOutlet
  ],
  styleUrls: ['./titan-button.component.scss']
})
export class TitanButtonComponent {

  @Input() isDisabled: boolean = false;
  @Input() onPress: () => void;
  @Input() variant: string = 'primary';
}
